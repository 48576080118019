import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { interval, Observable, Subscription } from 'rxjs';
import { AppConstants } from 'src/app/core/constants/appconstants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import * as $ from "jquery";
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { NearbyVehTripInfoComponent } from '../nearby-veh-trip-info/nearby-veh-trip-info.component';
declare let google: any;
declare let L;
import * as L from 'leaflet';


@Component({
  selector: 'app-panic-action-notification',
  templateUrl: './panic-action-notification.component.html',
  styleUrls: ['./panic-action-notification.component.scss']
})
export class PanicActionNotificationComponent implements OnInit, OnDestroy {

  mapCenter: any;
  tripListData: any[] = [];
  employeeStats: any[] = [];
  map: any = {};
  maps: any[] = [];
  marker: any;
  tripempmarkers: any = [];
  sitemarker: any;
  markers: any = [];
  colours = ["#4B0082"];
  sub: Subscription;
  isZoomed: Boolean = false;
  activatedEmpId: any;
  dialogData: any = {};
  panicActionData: any;
  currentUserId: any;
  public reason: any;
  ltlngs = [];
  sortedWP = [];
  _mCurrentUser: any;
  ivrSetupFlag: boolean = false;
  PanicUserId: any = '';
  private markers1: L.Marker[] = [];
  private vehicleMarkers: { [tripIndex: number]: L.Marker } = {};

  constructor(
    private serviceLocalStorage: ServiceLocalStorage,
    public dialogRef: MatDialogRef<PanicActionNotificationComponent>,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currentUserId = data.currentUserId;
    this.dialogData = data.tripData;
    this.panicActionData = data.panicActionData;
    this.tripListData = this.dialogData;
    this.calculateEmployees(this.dialogData);
    if (data.mapType === AppConstants.OSRM) {
      this.map = {};
      setTimeout(() => {
        this.loadOSmap();
        this.showTripOnOSMap();
        this.plotVehicle();
      }, 10);
    } else {
      google.maps.event.addDomListener(window, "load", this.initMap());
    }
    if (data.panicActionData[0].activatedBy == "EMPLOYEE APP") {
      this.PanicUserId = '';
      this.apiService.doGetRequestWithResponse(ApiConstants.getEmployeePanicbytripid + data.panicActionData[0].tripId.tripId, success => {
        if (success.status == 200) {
          this.activatedEmpId = success.body.employeeId;
          if (success.body) {
            this.ivrSetupFlag = success.body.sosCallFlag;
            this.PanicUserId = success.body.panicUserId;
            this.showSosLocation(success.body.lat, success.body.lng);
          }
        } else {
          this.activatedEmpId = "";
          this.PanicUserId = '';
          this.ivrSetupFlag = false;
        }
      }, error => {
        this.notifyService.showError(error, null);
      });
    } else {
      this.PanicUserId = '';
      this.apiService.doGetRequestWithResponse(ApiConstants.getDriverPanicbytripId + data.panicActionData[0].tripId.tripId, success => {
        if (success.status == 200) {
          this.activatedEmpId = success.body.driverId;
          if (success.body) {
            this.ivrSetupFlag = success.body.sosCallFlag;
            this.PanicUserId = success.body.panicId;
            this.showSosLocation(success.body.lat, success.body.lng);
          }
        } else {
          this.activatedEmpId = "";
          this.PanicUserId = '';
          this.ivrSetupFlag = false;
        }
      }, error => {
        this.notifyService.showError(error, null);
      });
    }
  }

  ngOnInit() {
    this._mCurrentUser = this.serviceLocalStorage.getUserObject();
  }

  updatePanicAction(data) {
    let actionTakeBy = { id: this.currentUserId };
    this.panicActionData[0].alarmCause = this.reason;
    (this.panicActionData[0].primaryActionTakenBy = actionTakeBy),
      (this.panicActionData[0].primaryAction = "Admin");
    this.ngxLoader.start();
    this.apiService.doPutRequestWithResponse(ApiConstants.updatePanicActionForAdmin + this.activatedEmpId, this.panicActionData[0], success => {
      this.ngxLoader.stop();
      if (success.status == 200) {
        this.notifyService.showInfo("Primary action taken by - " + success.body.curStatus + " ", null);
      } else if (success.status == 204) {
        this.notifyService.showSuccess("Updated successfully", null);
        this.dialogRef.close("close");
      }
    },
      error => {
        this.ngxLoader.stop();
        this.notifyService.showError(error, null);
      });
  }

  loadOSmap() {
    this.map = L.map("panicTripMap" + this.tripListData[0].tripDetails.tripId, {
      fullscreenControl: true
    }).setView([
      this.tripListData[0].tripDetails.site.landmark.latitude,
      this.tripListData[0].tripDetails.site.landmark.longitude
    ], 13);
    L.tileLayer(AppConstants.OSM_TILE, { attribution: AppConstants.OSM_CONTRIBUTORS }).addTo(this.map);
  }

  showTripOnOSMap() {
    if (this.tripListData[0].tripDetails.tripCode.indexOf('SPL') == -1) {
      let wps = [];
      if (this.tripListData[0].tripDetails.shift.logType == "IN") {
        this.tripListData[0].tripDetails.tripUsers.forEach(wp => {
          wps.push(L.latLng(wp.landmark.latitude, wp.landmark.longitude));
        });
        wps.push(
          L.latLng(
            this.tripListData[0].tripDetails.site.landmark.latitude,
            this.tripListData[0].tripDetails.site.landmark.longitude
          ));
      } else {
        wps.push(
          L.latLng(
            this.tripListData[0].tripDetails.site.landmark.latitude,
            this.tripListData[0].tripDetails.site.landmark.longitude
          ));
        this.tripListData[0].tripDetails.tripUsers.forEach(wp => {
          wps.push(L.latLng(wp.landmark.latitude, wp.landmark.longitude));
        });
      }
      let r = L.Routing.control({
        serviceUrl: AppConstants.OSM_ROUTING_URL,
        showAlternatives: false,
        lineOptions: {
          styles: [
            {
              color: "#242c81",
              weight: 7
            }
          ]
        },
        fitSelectedRoutes: true,
        waypointMode: "connect",
        addWaypoints: false,
        draggableWaypoints: false,
        autoRoute: true,
        reverseWaypoints: false,
        altLineOptions: {
          styles: [
            {
              color: "#ed6852",
              weight: 3
            }
          ]
        },
        show: true,
        routeWhileDragging: false,
        createMarker: (i, wp, nWps) => {
          if (this.tripListData[0].tripDetails.shift.logType === "IN") {
            if (i === this.tripListData[0].tripDetails.tripUsers.length) {
              let m = L.marker(wp.latLng, {
                icon: L.icon({
                  iconUrl: "./assets/images/company.png",
                  iconSize: [40, 40]
                }),
                title: this.tripListData[0].tripDetails.site.landmark.landmark
              });
              return m;
            }
          } else {
            if (i === 0) {
              let m = L.marker(wp.latLng, {
                icon: L.icon({
                  iconUrl: "./assets/images/company.png",
                  iconSize: [40, 40]
                }),
                title: this.tripListData[0].tripDetails.site.landmark.landmark
              });
              return m;
            }
          }
          if (this.tripListData[0].tripDetails.shift.logType === "IN") {
            if (i <= this.tripListData[0].tripDetails.tripUsers.length) {
              let m = L.marker(wp.latLng, {
                icon: L.icon({
                  iconUrl:
                    this.tripListData[0].tripDetails.tripUsers[i].tripUser.userInfoDetails.gender === "Female"
                      ? "./assets/images/livetracking/femaleloc.svg"
                      : "./assets/images/livetracking/maleloc.svg",
                  iconSize: [65, 70]
                }),
                title: this.tripListData[0].tripDetails.tripUsers[i].tripUser.userInfoDetails.displayName
              }).bindTooltip(i + 1 + "", {
                permanent: true,
                direction: "left",
                offset: L.point(-10, 0)
              }).openTooltip();
              return m;
            }
          } else {
            if (i - 1 <= this.tripListData[0].tripDetails.tripUsers.length) {
              let m = L.marker(wp.latLng, {
                icon: L.icon({
                  iconUrl:
                    this.tripListData[0].tripDetails.tripUsers[i - 1].tripUser.userInfoDetails.gender === "Female"
                      ? "./assets/images/livetracking/femaleloc.svg"
                      : "./assets/images/livetracking/maleloc.svg",
                  iconSize: [65, 70]
                }),
                title: this.tripListData[0].tripDetails.tripUsers[i - 1].tripUser.userInfoDetails.displayName
              })
                .bindTooltip(i + "", {
                  permanent: true,
                  direction: "left",
                  offset: L.point(-10, 0)
                })
                .openTooltip();
              return m;
            }
          }
        }
      }).addTo(this.map);
      r.setWaypoints(wps);
      r.hide();
    }
  }

  initMap() {
    if (this.tripListData) {
      setTimeout(() => {
        const latlng = {
          lat: this.tripListData[0].tripDetails.site.landmark.latitude,
          lng: this.tripListData[0].tripDetails.site.landmark.longitude
        };
        this.tripListData.forEach((trip, t) => {
          this.mapCenter = new google.maps.LatLng(latlng.lat, latlng.lng);
          const map = new google.maps.Map(
            document.getElementById("panicTripMap" + trip.tripDetails.tripId),
            {
              center: this.mapCenter,
              zoom: 12,
              draggable: true,
              zoomControl: true
            }
          );
          this.maps.push(map);
          this.showRoutesOnMap();
          this.markSite(this.tripListData[0].tripDetails.site, t);
        });
        this.plotVehicle();
      }, 50);
    } else {
      this.dialogRef.close("NoTrips");
    }
  }

  plotVehicle() {
    this.getVehiclePosition(false);
    this.sub = interval(3000).subscribe(val => {
      this.getVehiclePosition(true);
    });
  }

  setZoomForCab() {
    this.tripListData.forEach((t, tripIndex) => {
      this.maps[tripIndex].setCenter(this.markers[tripIndex].position);
      this.maps[tripIndex].setZoom(17);
    });
    this.isZoomed = true;
  }

  getVehiclePosition(iterate) {
    this.tripListData.forEach((trip, t) => {
      const body = {
        clientCode: this._mCurrentUser.clientCode
      };
      this.apiService.doPostRequestWithResponseForLiveTracking('fetchallgeoposition?tripId=' + trip.tripDetails.tripId + '&maptype=' + this.serviceLocalStorage.maptype, body, success => {
        if (this.data.mapType === AppConstants.OSRM) {
          this.plotVehicleMarkerOnOSmap(success.body, t);
        } else {
          if (iterate) {
            this.setVehiclePosition(success, t);
            if (!this.isZoomed) {
              this.setZoomForCab();
            }
          } else {
            this.plotVehicleMarker(success.body, t);
          }
        }
      }, error => {
        this.notifyService.showError(error, null);
      });
    });
  }
  
  plotVehicleMarkerOnOSmap(veh, tripIndex) {
    this.map.panTo(
      new L.LatLng(
        veh.waypoints[veh.waypoints.length - 1].latitude,
        veh.waypoints[veh.waypoints.length - 1].longitude
      )
    );
    let carMarker = new L.Icon({
      iconUrl: "./assets/images/livetracking/vehicle/FF503F.png",
      iconSize: [45, 40],
      iconAnchor: [15, 20]
    });

    if (this.vehicleMarkers[tripIndex]) {
      this.map.removeLayer(this.vehicleMarkers[tripIndex]);
    }

    this.vehicleMarkers[tripIndex] = L.marker([veh.waypoints[veh.waypoints.length - 1].latitude,
    veh.waypoints[veh.waypoints.length - 1].longitude], { icon: carMarker }).addTo(this.map);
    // let marker = new L.marker(
    //   [
    //     veh.waypoints[veh.waypoints.length - 1].latitude,
    //     veh.waypoints[veh.waypoints.length - 1].longitude
    //   ],
    //   { icon: carMarker }
    // ).addTo(this.map);
  }

  plotVehicleMarker(veh, tripIndex) {
    const path = "./assets/images/livetracking/vehicle/FF503F.png" +
      veh.tripId;
    const latlng = {
      lat: veh.positionList[veh.positionList.length - 1].latitude,
      lng: veh.positionList[veh.positionList.length - 1].longitude
    };
    const marker = new google.maps.Marker({
      position: latlng,
      animation: google.maps.Animation.DROP,
      draggable: false,
      icon: {
        url: path,
        fillColor: veh.vehicleColorCode ? veh.vehicleColorCode : "#c71300",
        fillOpacity: 1,
        scaledSize: new google.maps.Size(50, 50)
      },
      map: this.maps[tripIndex]
    });
    marker.customData = veh;
    this.markers.push(marker);
  }

  showRunningVehicle(data, tripIndex) {
    const lastPosition = data.body.positionList.length - 1;
    this.markers.forEach(m => {
      if (m.customData.tripId === this.tripListData[tripIndex].tripDetails.tripId) {
        const prevPosn = m.getPosition();
        const newPosition = new google.maps.LatLng(
          data.body.positionList[lastPosition].latitude,
          data.body.positionList[lastPosition].longitude
        );
        const direction = google.maps.geometry.spherical.computeHeading(prevPosn, newPosition);
        $('img[src="./assets/images/livetracking/vehicle/FF503F.png' + data.body.tripId + '"]').css({
          transform: "rotate(" + direction + "deg)",
          transition: "all 1s ease"
        });
        const icon = m.getIcon();
        icon.rotation = google.maps.geometry.spherical.computeHeading(
          prevPosn,
          newPosition
        );
        m.setIcon(icon);
      }
    });
    this.fetchETA(tripIndex, data.body.positionList);
  }

  fetchETA(tripIndex, vehiclePos) {
    let destLat, destLng;
    if (this.tripListData[tripIndex].tripDetails.shift.logType == "IN") {
      destLat = this.tripListData[tripIndex].tripDetails.tripUsers[0]
        ? this.tripListData[tripIndex].tripDetails.tripUsers[0].landmark.latitude : this.tripListData[tripIndex].tripDetails.site.landmark.latitude;
      destLng = this.tripListData[tripIndex].tripDetails.tripUsers[0]
        ? this.tripListData[tripIndex].tripDetails.tripUsers[0].landmark.longitude : this.tripListData[tripIndex].tripDetails.site.landmark.longitude;
    } else {
      destLat = this.tripListData[tripIndex].tripDetails.tripUsers[this.tripListData[tripIndex].tripDetails.tripUsers.length - 1].landmark.latitude;
      destLng = this.tripListData[tripIndex].tripDetails.tripUsers[this.tripListData[tripIndex].tripDetails.tripUsers.length - 1].landmark.longitude;
    }
    const body = {
      tripid: this.tripListData[tripIndex].tripDetails.tripId,
      srcLat: vehiclePos[vehiclePos.length - 1].latitude,
      srcLong: vehiclePos[vehiclePos.length - 1].longitude,
      destLat: destLat,
      destLong: destLng
    };
    this.apiService.doPostRequestWithResponse(ApiConstants.fetcheta, body, success => {
      if (success.body.tripid === this.tripListData[tripIndex].tripDetails.tripId) {
        this.tripListData[tripIndex].etaKm = success.body.distance ? success.body.distance : 0;
        this.tripListData[tripIndex].etaMin = success.body.time ? success.body.time : "NA";
        this.tripListData[tripIndex].consumedMin = success.body.timeElapsed ? success.body.timeElapsed.slice(3, 8) : "NA";
        this.tripListData[tripIndex].consumedKm = success.body.distanceCovered ? success.body.distanceCovered : 0;
        this.tripListData[tripIndex].lateTime = success.body.lateTime ? success.body.lateTime : 0;
      }
    }, error => {
      this.notifyService.showError(error, null);
    });
  }

  setVehiclePositionOnOSmap(data, tripIndex) {
    const vehiclePlanCoordinates = [];
    if (data.body) {
      this.showRunningVehicle(data, tripIndex);
      data.body.positionList.forEach(vehicle => {
        vehiclePlanCoordinates.push({
          lat: vehicle.latitude,
          lng: vehicle.longitude
        });
      });
      const flightPath = new google.maps.Polyline({
        path: vehiclePlanCoordinates,
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2
      });
      flightPath.setMap(this.maps[tripIndex]);
    }
  }

  setVehiclePosition(data, tripIndex) {
    const vehiclePlanCoordinates = [];
    if (data.body) {
      this.showRunningVehicle(data, tripIndex);
      data.body.positionList.forEach(vehicle => {
        vehiclePlanCoordinates.push({
          lat: vehicle.latitude,
          lng: vehicle.longitude
        });
      });
      const flightPath = new google.maps.Polyline({
        path: vehiclePlanCoordinates,
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2
      });
      flightPath.setMap(this.maps[tripIndex]);
    }
  }

  showRoutesOnMap() {
    let counter = 0;
    let r = 0.05;
    this.tripListData.forEach((trip, t) => {
      let waypts = [];
      let startnodelatlng = {
        lat: trip.tripDetails.site.landmark.latitude,
        lng: trip.tripDetails.site.landmark.longitude
      };
      let endnodelatlng = {
        lat: trip.tripDetails.site.landmark.latitude,
        lng: trip.tripDetails.site.landmark.longitude
      };
      if (trip.tripDetails.shift) {
        if (trip.tripDetails.shift.logType == "IN") {
          startnodelatlng = {
            lat: trip.tripDetails.tripUsers[0] ? trip.tripDetails.tripUsers[0].showStatus == "SHOW" ? trip.tripDetails.tripUsers[0].booardedLatitude
              ? trip.tripDetails.tripUsers[0].booardedLatitude : trip.tripDetails.tripUsers[0].landmark.latitude
              : trip.tripDetails.tripUsers[0].landmark.latitude : trip.tripDetails.site.landmark.latitude,
            lng: trip.tripDetails.tripUsers[0] ? trip.tripDetails.tripUsers[0].showStatus == "SHOW" ? trip.tripDetails.tripUsers[0].booardedLongitude
              ? trip.tripDetails.tripUsers[0].booardedLongitude : trip.tripDetails.tripUsers[0].landmark.longitude
              : trip.tripDetails.tripUsers[0].landmark.longitude : trip.tripDetails.site.landmark.longitude
          };
        } else {
          const tripLength = trip.tripDetails.tripUsers.length - 1;
          endnodelatlng = {
            lat: trip.tripDetails.tripUsers[tripLength].showStatus == "SHOW" ? trip.tripDetails.tripUsers[tripLength].arrivalLatitude
              ? trip.tripDetails.tripUsers[tripLength].arrivalLatitude : trip.tripDetails.tripUsers[tripLength].landmark.latitude
              : trip.tripDetails.tripUsers[tripLength].landmark.latitude,
            lng: trip.tripDetails.tripUsers[tripLength].showStatus == "SHOW" ? trip.tripDetails.tripUsers[tripLength].arrivalLongitude
              ? trip.tripDetails.tripUsers[tripLength].arrivalLongitude : trip.tripDetails.tripUsers[tripLength].landmark.longitude
              : trip.tripDetails.tripUsers[tripLength].landmark.longitude
          };
        }
      }
      let markerlist = [];
      trip.tripDetails.tripUsers.forEach((tripuser, tIndex) => {
        waypts.push({
          location: {
            lat: tripuser.showStatus == "SHOW" && trip.tripDetails.shift.logType == "IN" ? tripuser.booardedLatitude ? tripuser.booardedLatitude
              : tripuser.landmark.latitude : tripuser.landmark.latitude,
            lng: tripuser.showStatus == "SHOW" && trip.tripDetails.shift.logType == "IN" ? tripuser.booardedLongitude ? tripuser.booardedLongitude
              : tripuser.landmark.longitude : tripuser.landmark.longitude
          },
          stopover: true
        });
        let icon = tripuser.showStatus == "SHOW" ? "./assets/images/livetracking/pickedup_male.svg" : "./assets/images/livetracking/male_noshow.svg";
        if (tripuser.tripUser.userInfoDetails.gender == "Female") {
          icon = tripuser.showStatus == "SHOW" ? "./assets/images/livetracking/pickedup_female.svg" : "./assets/images/livetracking/female_noshow.svg";
        }
        var marker = new google.maps.Marker({
          position: {
            lat: tripuser.showStatus == "SHOW" && trip.tripDetails.shift.logType == "IN" ? tripuser.booardedLatitude ? tripuser.booardedLatitude
              : tripuser.landmark.latitude : tripuser.landmark.latitude,
            lng: tripuser.showStatus == "SHOW" && trip.tripDetails.shift.logType == "IN" ? tripuser.booardedLongitude ? tripuser.booardedLongitude
              : tripuser.landmark.longitude : tripuser.landmark.longitude
          },
          icon: icon,
          draggable: false,
          map: this.maps[t],
          title: tripuser.tripUser.userInfoDetails.displayName + " - " + tripuser.routedOrder
        });
        marker.customData = {
          tripId: trip.tripDetails.tripId,
          tripIndex: t,
          logType: trip.tripDetails.shift.logType,
          userId: tripuser.tripUser.userInfoDetails.id,
          tripUser: tripuser
        };
        google.maps.event.addListener(marker, "click", () => {
          this.fetchUserHomePickup(marker.customData);
        });
        markerlist.push(marker);
      });
      this.tripempmarkers.push({
        tripid: trip.tripDetails.tripId,
        markers: markerlist
      });
      if (this.sitemarker == undefined) {
        this.markSite(trip.tripDetails.site, t);
      }
      let request = {
        origin: startnodelatlng,
        destination: endnodelatlng,
        waypoints: waypts,
        optimizeWaypoints: false,
        avoidHighways: false,
        travelMode: google.maps.DirectionsTravelMode.DRIVING
      };
      let directionsService = new google.maps.DirectionsService();
      let directionsDisplay = new google.maps.DirectionsRenderer({
        polylineOptions: {
          strokeColor: this.colours[0],
          strokeWeight: 3 + (t + 1) * 5,
          strokeOpacity: 0.5 + r
        }
      });
      r = r + 0.05;
      directionsDisplay.setMap(this.maps[t]);
      directionsDisplay.setOptions({ suppressMarkers: true });
      directionsService.route(request, (response, status) => {
        if (status == google.maps.DirectionsStatus.OK) {
          directionsDisplay.setDirections(response);
        }
      });
    });
  }

  fetchUserHomePickup(userData) {
    this.apiService.doGetRequestWithResponse(ApiConstants.fetchuserhomepickup + userData.tripId + "&userId=" + userData.userId + "&logType=" + userData.logType, success => {
      this.setPickupPoint(userData, success.body);
      this.setHomePoint(userData, success.body);
    }, error => {
      this.notifyService.showError(error, null);
    }
    );
  }

  setHomePoint(userData, data) {
    this.addMarker(userData, data, "home");
    this.setRoute(userData, data, "home");
  }

  setPickupPoint(userData, data) {
    this.addMarker(userData, data, "pickup");
    this.setRoute(userData, data, "pickup");
  }

  addMarker(userData, data, type) {
    var marker = new google.maps.Marker({
      position: {
        lat: type == "home" ? data.homeLandmark.latitude : data.pickupLandmark.latitude,
        lng: type == "home" ? data.homeLandmark.longitude : data.pickupLandmark.longitude
      },
      icon: type == "home" ? "./assets/images/home.png" : "./assets/images/livetracking/driver.png",
      draggable: false,
      map: this.maps[userData.tripIndex],
      title: data.homeLandmark.area.area
    });
  }

  setRoute(userData, data, type) {
    let startnodelatlng = {
      lat: userData.tripUser.showStatus == "SHOW" ? userData.tripUser.booardedLatitude : userData.tripUser.landmark.latitude,
      lng: userData.tripUser.showStatus == "SHOW" ? userData.tripUser.booardedLongitude : userData.tripUser.landmark.longitude
    };
    let endnodelatlng = {
      lat: type == "home" ? data.homeLandmark.latitude : data.pickupLandmark.latitude,
      lng: type == "home" ? data.homeLandmark.longitude : data.pickupLandmark.longitude
    };
    let waypts = [{
      location: startnodelatlng,
      stopover: true
    },
    {
      location: endnodelatlng,
      stopover: true
    }];
    let request = {
      origin: startnodelatlng,
      destination: endnodelatlng,
      waypoints: waypts,
      optimizeWaypoints: false,
      avoidHighways: false,
      travelMode: google.maps.DirectionsTravelMode.DRIVING
    };
    let directionsService = new google.maps.DirectionsService();
    let directionsDisplay = new google.maps.DirectionsRenderer({
      preserveViewport: true,
      polylineOptions: {
        strokeColor: type === "home" ? "#080" : "#800", //t
        strokeWeight: 3,
        strokeOpacity: 0.7
      }
    });
    directionsDisplay.setMap(this.maps[userData.tripIndex]);
    directionsDisplay.setOptions({ suppressMarkers: true });
    directionsService.route(request, (response, status) => {
      if (status == google.maps.DirectionsStatus.OK) {
        directionsDisplay.setDirections(response);
      }
    });
  }

  markSite(site, mapIndex) {
    const sitecordinates = new google.maps.LatLng(
      site.landmark.latitude,
      site.landmark.longitude
    );
    const path = "./assets/img/company.png";
    const sitemarker = new google.maps.Marker({
      position: sitecordinates,
      icon: {
        url: path,
        scaledSize: new google.maps.Size(40, 40)
      },
      map: this.maps[mapIndex],
      title: site.siteName
    });
  }

  calculateEmployees(trips) {
    trips.forEach((trip, t) => {
      this.employeeStats.push({
        femaleEmployees: 0,
        maleEmployees: 0,
        totalEmployees: 0,
        femaleShowStats: 0,
        maleShowStats: 0,
        femaleNoShowStats: 0,
        maleNoShowStats: 0,
        currentLocationPin: 0
      });
      this.employeeStats[t].totalEmployees = trip.tripDetails.tripUsers.length;
      trip.tripDetails.tripUsers.forEach((emp, e) => {
        emp.showStatus === "SHOW" ? (this.employeeStats[t].currentLocationPin = e + 1) : "";
        if (emp.tripUser.userInfoDetails.gender === "Female") {
          this.employeeStats[t].femaleEmployees++;
          if (emp.showStatus === "SHOW") {
            this.employeeStats[t].femaleShowStats++;
          }
          if (emp.showStatus === "NO SHOW") {
            this.employeeStats[t].femaleNoShowStats++;
          }
        } else {
          this.employeeStats[t].maleEmployees++;
          if (emp.showStatus === "SHOW") {
            this.employeeStats[t].maleShowStats++;
          }
          if (emp.showStatus === "NO SHOW") {
            this.employeeStats[t].maleNoShowStats++;
          }
        }
      });
    });
  }

  showSosLocation(latitude, longitude) {
    this.marker = L.marker([latitude, longitude], {
      icon: new L.Icon({
        iconUrl: "./assets/images/sos.svg",
        iconSize: [80, 80],
        iconAnchor: [33, 73]
      })
    })
      .addTo(this.map)
  }

  doIVRCall(tripUserId, tripId, empType) {
    this.ngxLoader.start();
    if (empType == 'driver') {
      this.apiService.doPostRequestWithResponse(ApiConstants.ivrEmpCall + tripId + '&driverId=' + tripUserId, '', (success) => {
        if (success.status == 200) {
          this.ngxLoader.stop();
        } else {
          this.ngxLoader.stop();
        }
      }, (error) => {
        this.ngxLoader.stop();
        console.log(error);
      });
    } else if (empType == 'escort') {
      this.apiService.doPostRequestWithResponse(ApiConstants.ivrEmpCall + tripId + '&escortId=' + tripUserId, '', (success) => {
        if (success.status == 200) {
          this.ngxLoader.stop();
        } else {
          this.ngxLoader.stop();
        }
      }, (error) => {
        this.ngxLoader.stop();
        console.log(error);
      });
    } else {
      this.apiService.doPostRequestWithResponse(ApiConstants.ivrEmpCall + tripId + '&tripUserId=' + tripUserId, '', (success) => {
        if (success.status == 200) {
          this.ngxLoader.stop();
        } else {
          this.ngxLoader.stop();
        }
      }, (error) => {
        this.ngxLoader.stop();
        console.log(error);
      });
    }
  }
  
  showNearByVehicle() {
    if (this.data.panicActionData[0].activatedBy == "EMPLOYEE APP") {
      this.ngxLoader.start();
      this.apiService.doGetRequestWithResponse(ApiConstants.showNearByVehile + this.panicActionData[0].tripId.tripId + '&userId=' + this.PanicUserId,
        success => {
          if (success.status == 200) {
            this.ngxLoader.stop();
            this.clearMarkers();
            if (success.body.length > 0) {
              success.body.forEach(ele => {
                this.marker = L.marker([ele.latitude, ele.longitude], {
                  icon: new L.Icon({
                    iconUrl:
                      "./assets/images/livetracking/vehicle/843C0C.png",
                    iconSize: [45, 40],
                    iconAnchor: [15, 20]
                  })
                })
                  .addTo(this.map)
                  .bindPopup(
                    $('<b style="cursor:pointer">' + ele.vehicleNo + "</b>").click(
                      () => {
                        this.showVehicleInfo(ele.tripId);
                      }
                    )[0]
                  );
                this.markers1.push(this.marker);
              });
            }
          } else {
            this.ngxLoader.stop();
            this.notifyService.showInfo('No vehicle found within a 2KM radius.', null);
          }
        }, (error) => {
          this.ngxLoader.stop();
        });
    } else {
      this.ngxLoader.start();
      this.apiService.doGetRequestWithResponse(ApiConstants.showNearByVehile + this.panicActionData[0].tripId.tripId,
        success => {
          if (success.status == 200) {
            this.ngxLoader.stop();
            this.clearMarkers();
            if (success.body.length > 0) {
              success.body.forEach(ele => {
                this.marker = L.marker([ele.latitude, ele.longitude], {
                  icon: new L.Icon({
                    iconUrl:
                      "./assets/images/livetracking/vehicle/843C0C.png",
                    iconSize: [45, 40],
                    iconAnchor: [15, 20]
                  })
                })
                  .addTo(this.map)
                  .bindPopup(
                    $('<b style="cursor:pointer">' + ele.vehicleNo + "</b>").click(
                      () => {
                        this.showVehicleInfo(ele.tripId);
                      }
                    )[0]
                  );
                this.markers1.push(this.marker);
              });
            }
          } else {
            this.ngxLoader.stop();
            this.notifyService.showInfo('No vehicle found within a 2KM radius.', null);
          }
        }, (error) => {
          this.ngxLoader.stop();
        });
    }
  }

  clearMarkers() {
    this.markers1.forEach(marker => this.map.removeLayer(marker));
    this.markers1 = []; 
  }

  showVehicleInfo(tripId) {
    let sentData = {
      tripId: tripId,
      ivrSetupFlag: this.ivrSetupFlag
    }
    let dialogRef = this.dialog.open(NearbyVehTripInfoComponent, {
      width: "700px",
      data: sentData,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

}
